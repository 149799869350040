import { contentContainer, title } from "./styles/impressum.module.scss";

import React from "react";
import ReactMarkdown from "react-markdown";
import { graphql } from "gatsby";

const Impressum = ({ data }) => {
  const content = data.strapiImprint?.content;
  return (
    <div className={`${contentContainer} fade-in`}>
      <h2 className={title}>impressum</h2>
      <ReactMarkdown>{content}</ReactMarkdown>
    </div>
  );
};

Impressum.seoOptions = {
  title: "Impressum",
};
export default Impressum;

export const query = graphql`
  query {
    strapiImprint {
      content
    }
  }
`;
