// extracted by mini-css-extract-plugin
export var dNone = "impressum-module--d-none--Zy5h2";
export var dBlock = "impressum-module--d-block--S24mF";
export var dInline = "impressum-module--d-inline--NjXJQ";
export var dFlex = "impressum-module--d-flex--5f9yu";
export var dMdNone = "impressum-module--d-md-none--iVIEM";
export var dMdBlock = "impressum-module--d-md-block--H0R1+";
export var dMdFlex = "impressum-module--d-md-flex--TFLfp";
export var dLgFlex = "impressum-module--d-lg-flex--4ovgJ";
export var dLgNone = "impressum-module--d-lg-none--Zs2h8";
export var dLgBlock = "impressum-module--d-lg-block--wDGVm";
export var dLgInline = "impressum-module--d-lg-inline--Updhq";
export var title = "impressum-module--title--00nY-";
export var contentContainer = "impressum-module--contentContainer--TIwx+";